// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"

import * as ActiveStorage from "@rails/activestorage"
import "channels"
require("jquery")
require("jquery-ui");
require ('spectrum_js/common')
require ('spectrum_js/aos')
require ('spectrum_js/slick.min')


Rails.start()
require('/node_modules/owl.carousel2/dist/owl.carousel.min.js')
require ('trix')
require ('@rails/actiontext')
ActiveStorage.start()




require("trix")
require("@rails/actiontext")

function seprateData(){
  var categoryIndex = '';
  var industryIndex = '';
  setTimeout(function () {

    
    $('li.ui-menu-item').each(function (index, el) {
      var lol = $(this).children('div.ui-menu-item-wrapper').text();
      if (lol === 'Industries Data'){
        categoryIndex = (index)
      } else if (lol === 'Products'){
        industryIndex = (index)
      }
    });
    $('li.ui-menu-item').each(function (index) {
      if (index < categoryIndex){
        $(this).addClass("category_fetch");
        
      }else if (index >= categoryIndex && index < industryIndex){
        $(this).addClass("industry_fetch").css("position");
      } else if (index >= industryIndex){
        $(this).addClass("products_fetch");
      };
    });

      $("#ui-id-1").prepend("<div id='cat-id' class='ul-box'><div id='cat-inner'></div></div>")
      $(".category_fetch").insertBefore("#cat-inner")

      $("#ui-id-1").prepend("<div id='ind-id' class='ul-box'><div id='ind-inner'></div></div>")
      $(".industry_fetch").insertBefore("#ind-inner")

      $("#ui-id-1").prepend("<div id='pro-id' class='ul-box'><div id='pro-inner'></div></div>")
      $(".products_fetch").insertBefore("#pro-inner")
  }, 100);

};