// document ready function
$(document).ready(function(){
  $(document).click(function() {
    $(".sub-menu").removeClass("active")
  });

  $('.sub-menu').click(function(e){
  e.stopPropagation();
  });
  $("#menu-category-list").insertAfter("#prd-menu i")
  $("#menu-industry-list").insertAfter("#ind-menu i")
  
  // $(".apply-btn").click(function(){
  //   $(".request-box, .enq-overlay").toggleClass("active")
  // })

  // data-tab for blogs
  $(document).on('click','[data-tab]',function(){
    $(this).addClass("active");
    $(this).siblings().removeClass("active");  
    var dataTab=$(this).attr("data-tab");
    $('#' + dataTab).addClass("active");
    $('#' + dataTab).siblings().removeClass("active");
  })

  // $(".bl-section .boxes .box").first().addClass("active");
  // data-tab end



  $(".apply-btn").click(function(){
    $(".career-form,.enq-overlay").addClass("active")
    var id = $(this).attr("id");
    $('#career_detail_job_details_id').val(id);
  });

  $(document).on("click", "[data-menu]",function(e){
    $(this).siblings().find(".sub-menu").removeClass("active")
    $(this).find(".sub-menu").toggleClass("active")
    e.stopPropagation();
  });
  $(".prd-box").click(function(){
    var c_url = $(this).attr("for");
    window.location.href = c_url;
  });
   AOS.init();

  // $(".sec-prd-box").click(function(){
 //    var c_url = $(this).attr("for");
 //    window.location.href = c_url;
 //  });

  $(".top-product").click(function(){
    var t_url = $(this).attr("for");
    window.location.href = t_url;          
  });

  $(".product-cate-boxes").click(function(){
    var c_url = $(this).attr("for");
    window.location.href = c_url;
  });


  $(".product_list").click(function(){
    var c_url = $(this).attr("for");
    window.location.href = c_url;
  });

  $(".menu-btn").click(function(){
    $(".right-boxes").addClass("active");
 
  })
  $(".menu-bttn").click(function(){
  $(".left-box").slideToggle("active")
  })

  $(".close-menu").click(function(){
    $(".right-boxes").removeClass("active")
  })
  $(document).on("click", ".accordian-row h3",function(){
    $(this).parent().siblings().find(".dropdown-list").slideUp();
    //$(this).addClass("active");
    $(this).next(".dropdown-list").slideToggle();
  })
    $(".accordian-row1 h2 i").click(function(){
    $(this).parent().siblings().find("i").removeClass("active");
    $(this).parent().siblings().find(".p-list").slideUp();
    $(this).addClass("active");
    $(this).next(".p-list").slideToggle();
  })
  $(".arrow").click(function() {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    return false;
  });

    $(window).scroll(function(){
    var scroll = $(window).scrollTop();
    if (scroll >= 100) {
      $(".back-to").addClass("active")      
    }else{
      $(".back-to").removeClass("active")
    }
  });


  $('.navbar.right-boxes ul li').click(function() {
    $('.navbar.right-boxes ul li.active').removeClass('active');
    $(this).addClass('active');
  });

//   $(window).scroll(function(){
//   if($(this).scrollTop() > 90){
//     $(".header-bg").addClass('active')
//   } else{
//     $(".header-bg").removeClass('active')
//   }
// })

  $(document).on("click", ".close-popup",function(){
    $(".request-box, .enq-overlay, .request-form ").removeClass("active")
  })


  $(".sticy-bar, .call-back").click(function(){
    $(".request-box, .enq-overlay").toggleClass("active")
  })
  $(".accordian-box h3, .slide-up").click(function(){
    $(this).parent().siblings().find("h3, .slide-up").removeClass("active");
    $(this).parent().siblings().find("p, .tr-row tr").slideUp();
    $(this).addClass("active");
    $(this).next("p, .tr-row tr").slideToggle();
  })

  $(".category-box, .concern-box, .industry-box").click(function(){
    var industry = $(this).attr("for_industry");
    var category = $(this).attr("for_category");
    var concern = $(this).attr("for_concern");
    var type = $(this).attr("type");
    if(type == "Industry"){
      $.getScript('/category_details/'+industry);
    }
    else if(type == "Category"){
      $.getScript('/category_details/'+category);
    }
    else{
      $.getScript('/category_details/'+concern);          
    }
  })
$(document).on("click", "[data-show]", function(){
  var innerShow = $(".inner-show-hide01").outerHeight() + 22
    var thisText = $(this).find('.rm-btn span').text()
    var eleShow = $(this).prev('.show-hide01')
    var eleHeight = eleShow.outerHeight()
    if(eleHeight == 65){
      eleShow.animate({height: innerShow}, 1000)
      $('.rm-btn span').text("Read less")
      $('.rm-btn img').css("transform", "rotate(180deg)");

    } else{
      eleShow.animate({height: 65}, 1000)
      $('.rm-btn span').text("Read more")
      $('.rm-btn img').css("transform", "initial");
    }
  })

  var singElmH = $(".inner-tp-hide .product-boxes").outerHeight()
  var p = $(".inner-tp-hide").height(singElmH + 60)
  $(document).on("click", "[data-show]", function(){
    var innerShow = $(".inner-box3").outerHeight() + 60
    var thisText = $(this).find('.tp-btn span').text()
    var eleShow = $(this).prev('.inner-tp-hide')
    var eleHeight = eleShow.outerHeight()
    if(eleHeight == singElmH){
      eleShow.animate({height: innerShow}, 1000)
      $('.tp-btn span').text("SHOW LESS PRODUCTS")
      $('.tp-btn img').css("transform", "rotate(180deg)");
    } else{
      eleShow.animate({height: singElmH}, 1000)
      $('.tp-btn span').text("SHOW ALL PRODUCTS")
      $('.tp-btn img').css("transform", "initial");
    }
  })
  if($(window).width() >=769){
    $(document).on("click", "[data-show1]", function(){
    $('.why-section .box-3').toggleClass("active");
    $('.why-section .inner-se-hide-solutions').toggleClass("active");
    $('.why-section .box-3 .why-box:nth-child(-n+4)').removeClass("hidden");
    var innerShow = $(".inner-services").outerHeight()
    var thisText = $(this).find('.se-btn span').text()
    var eleShow = $(this).prev('.inner-se-hide')
    var eleHeight = eleShow.outerHeight()
    $(this).find('.se-btn span').text(thisText === "SHOW MORE SOLUTIONS" ? "SHOW LESS SOLUTIONS" : "SHOW MORE SOLUTIONS") 
    if($('.why-section .box-3').hasClass("active")){
      eleShow.animate({height: innerShow}, 1000)
     // $('.se-btn span').text("SHOW MORE SOLUTIONS")
      $('.se-btn  img').css("transform", "rotate(180deg)");

    } else{
      eleShow.animate({height: 259}, 1000)
      //$('.se-btn span').text("SHOW LESS SOLUTIONS")
      $('.se-btn img').css("transform", "initial");

    }
  }  )
  var elementbox3=$('.box-3');
  var elementinnersolution=$('.inner-se-hide-solutions');
  var height=1122;
  var elementheight=height+"px";


  }
  if($(window).width() <=769){
    $(document).on("click", "[data-show1]", function(){
    $('.why-section .box-3').toggleClass("active");
    $('.why-section .inner-se-hide-solutions').toggleClass("active");
    $('.why-section .box-3 .why-box:nth-child(-n+4)').removeClass("hidden");
    var innerShow = $(".inner-services").outerHeight()
    var thisText = $(this).find('.se-btn span').text()
    var eleShow = $(this).prev('.inner-se-hide')
    var eleHeight = eleShow.outerHeight()
    $(this).find('.se-btn span').text(thisText === "SHOW MORE SOLUTIONS" ? "SHOW LESS SOLUTIONS" : "SHOW MORE SOLUTIONS") 
    if(eleHeight == 225){
      eleShow.animate({height: innerShow}, 1000)
      //$('.se-btn span').text("SHOW LESS SOLUTIONS")
      $('.se-btn img').css("transform", "rotate(180deg)");

    } else{
      eleShow.animate({height: 225}, 1000)
      //$('.se-btn span').text("SHOW MORE SOLUTIONS")
      $('.se-btn img').css("transform", "initial");

    }
  })
  }
  
  // box3H = $(".box-3").outerHeight()
  // whyBxH = $(".why-box").outerHeight()
  // $(".inner-se-hide-solutions").height(whyBxH)
  // $(".se-btn").click(function(){
  //   if($(".inner-se-hide-solutions").height() == whyBxH){
  //     $(".inner-se-hide-solutions").animate({height: box3H}, 300)
  //   } else{
  //     $(".inner-se-hide-solutions").animate({height: whyBxH}, 300)
  //   }
    
  // })

  $(".se-btn").click(function(){
    $(".why-box.hidden").toggle();
    
    
  })

// 
  // if($(window).width() >=769){
  //   $(document).on("click", "[data-show1]", function(){
  //   var innerShow = $(".inner-services").outerHeight() + 350
  //   var thisText = $(this).find('.se-btn span').text()
  //   var eleShow = $(this).prev('.inner-se-hide-solutions')
  //   var eleHeight = eleShow.outerHeight()
  //   if(eleHeight == 320){
  //     eleShow.animate({height: innerShow}, 1000)
  //     $('.se-btn span').text("SHOW LESS SOLUTIONS")
  //     $('.se-btn img').css("transform", "rotate(180deg)");

  //   } else{
  //     eleShow.animate({height: 320}, 1000)
  //     $('.se-btn span').text("SHOW ALL SOLUTIONS")
  //     $('.se-btn img').css("transform", "initial");

  //   }
  // })
  // }
  // if($(window).width() <=1024){
  //   $(document).on("click", "[data-show1]", function(){
  //   var innerShow = $(".inner-services").outerHeight()+220
  //   var thisText = $(this).find('.se-btn span').text()
  //   var eleShow = $(this).prev('.inner-se-hide-solutions')
  //   var eleHeight = eleShow.outerHeight()
  //   if(eleHeight == 265){
  //     eleShow.animate({height: innerShow}, 1000)
  //     $('.se-btn span').text("SHOW LESS SOLUTIONS")
  //     $('.se-btn img').css("transform", "rotate(180deg)");

  //   } else{
  //     eleShow.animate({height: 265}, 1000)
  //     $('.se-btn span').text("SHOW LESS SOLUTIONS")
  //     $('.se-btn img').css("transform", "initial");

  //   }
  // })
  // }

  // if($(window).width() <=769){
  //   $(document).on("click", "[data-show1]", function(){
  //   var innerShow = $(".inner-services").outerHeight()+150
  //   var thisText = $(this).find('.se-btn span').text()
  //   var eleShow = $(this).prev('.inner-se-hide-solutions')
  //   var eleHeight = eleShow.outerHeight()
  //   if(eleHeight == 265){
  //     eleShow.animate({height: innerShow}, 1000)
  //     $('.se-btn span').text("SHOW LESS SOLUTIONS")
  //     $('.se-btn img').css("transform", "rotate(180deg)");

  //   } else{
  //     eleShow.animate({height: 265}, 1000)
  //     $('.se-btn span').text("SHOW LESS SOLUTIONS")
  //     $('.se-btn img').css("transform", "initial");

  //   }
  // })
  // }

  // if($(window).width() <=640){
  //   $(document).on("click", "[data-show1]", function(){
  //   var innerShow = $(".inner-services").outerHeight()+2600
  //   var thisText = $(this).find('.se-btn span').text()
  //   var eleShow = $(this).prev('.inner-se-hide-solutions')
  //   var eleHeight = eleShow.outerHeight()
  //   if(eleHeight == 265){
  //     eleShow.animate({height: innerShow}, 1000)
  //     $('.se-btn span').text("SHOW LESS SOLUTIONS")
  //     $('.se-btn img').css("transform", "rotate(180deg)");

  //   } else{
  //     eleShow.animate({height: 265}, 1000)
  //     $('.se-btn span').text("SHOW LESS SOLUTIONS")
  //     $('.se-btn img').css("transform", "initial");

  //   }
  // })
  // }

  $(".read-m-btn").click(function(){
    $(".img2-text").toggleClass("active")
    $(this).text($(this).text() == "Read Less" ? "Read More..." : "Read Less")
  })
  //   $(".read-more-btn").click(function(){
  //   $(this).parent().find(".box-3 .img-text").toggleClass("active")
  //   $(this).text($(this).text() == "Read More..." ? "Read Less" : "Read More...")
  // })

  $(".main-box .input001").click(function(){
    $(".down-b-btn, .enq-overlay").toggleClass("active");
  })
    $(".close-popup").click(function(){
    $(".down-b-btn, .enq-overlay").removeClass("active");
    $(".enq-overlay, .career-form ").removeClass("active")
  })

    $(".read-more-btn").click(function(){
      // alert("hii")
      $(this).parent().find(".sh-ul-parent").toggleClass("active");
      $(this).text($(this).text()=="Read Less" ? "Read More..." : "Read Less")
    });

    $(".read-more-btn1").click(function(){
      $(this).parent().find(".sh-ul-parent1").toggleClass("active");
      $(this).text($(this).text()=="Read Less" ? "Read More..." : "Read Less")
    });

});


$(document).ready(function(){
  $(".search-logo").click(function(){
    $(".search-box").toggleClass("active");
    $("#auto-focus").focus();
  })
})



// $(function() {
//   $(".why-box").slice(0, 4).show(); // select the first ten
//   $("#load").click(function(e) { // click event for load more
//     e.preventDefault();
//     $(".why-box:hidden").slice(0, 6).show();
//     // if($($(".why-box").hasClass("hide-show"))){
//     //   setTimeout(function(){
//     //     $(".why-box.hide-show").hide()
//     //   },500)
//     // } 
//     // $(".why-box:hidden").slice(0, 6).show(); // select next 10 hidden divs and show them
//     // //$(this).hide()
//     // if ($(".why-box:hidden").length == 0) { // check if any hidden divs still exist
//     //   $('.se-btn img').css("transform", "initial");
//     // }
//   });
// });



// 
// $(function() {
//   $(".why-box").slice(0, 4).show(); // select the first ten
//   $("#load").click(function(e) { // click event for load more
//     e.preventDefault();
//     $(".why-box:hidden").slice(0, 6).show(); // select next 10 hidden divs and show them
//     //$(this).hide()
//     if ($(".why-box:hidden").length == 0) { // check if any hidden divs still exist
//       $('.se-btn img').css("transform", "initial");
//     }
//   });
// });


// careers page
